import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tap from "@tapfiliate/tapfiliate-js";
import axios from "axios";
import Chip from "components/general/formComponents/chip/Chip";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";
// Images
import FoodIconOne from "assets/images/App/techniker-krankenkasse-logo.svg";
import FoodIconTwo from "assets/images/App/aok.svg";
import FoodIconThree from "assets/images/App/DAK-Gesundheit_logo.svg";
import FoodIconFour from "assets/images/App/barmer_krankenkasse_logo.svg";
import FoodIconFive from "assets/images/App/ikk-gesundplus.svg";

import * as CoachActions from "redux/coach/coach.actions";
import * as G from "styles/General.Styles";
import * as C from "./LeadsSurvey.style";
import LeadsSurveyForm from "./LeadsSurveyForm";

const LeadsSurvey = () => {
  const dispatch = useDispatch();
  const saveTrainerLeads = useSelector((state) => state.coach.saveTrainerLeads);
  const isTrainerLeadSaving = useSelector(
    (state) => state.coach.isTrainerLeadSaving
  );

  const trainerProfileData = useSelector((state) => state.auth.trainerData);

  const [CurrentStep, setCurrentStep] = useState(1);

  const [SelectedStepOne, setSelectedStepOne] = useState(null);
  const [SelectedStepTwo, setSelectedStepTwo] = useState(null);
  const [SelectedStepThree, setSelectedStepThree] = useState(null);
  const [ipAddress, setIpAddress] = useState("");
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Message, setMessage] = useState("");
  const [IsSending, setIsSending] = useState(false);
  const [EmailHasSent, setEmailHasSent] = useState(false);

  useEffect(() => {}, []);

  return (
    <C.Wrap>
      <C.ContentSection>
        <Chip text="DEIN KOSTENLOSES ERSTGESPRÄCH" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Mit meiner Unterstützung erreichst du dein Ziel 🙌
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Bitte fülle dieses Formular aus, damit ich genau weiß, wie ich dir auf
          deiner Abnehmreise am besten helfen kann.
        </C.Description>
        <C.PlanBoxIcons className="procedure">
          {[
            "Lerne mich in einem kostenlosem und unverbindlichem Video-Call kennen.",
            "Erstelle gemeinsam mit mir deinen persönlichen Ernährungsplan.",
            "Stell mir deine Fragen rund um das Thema Ernährung, Ernährungsplanung und Coaching. Profitiere von meinem Expertenwissen.",
          ].map((t) => (
            <li>
              <C.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </C.PlanBoxIconBox>
              <C.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
                {t}
              </C.PlanBoxInformation>
            </li>
          ))}
        </C.PlanBoxIcons>
      </C.ContentSection>
      <LeadsSurveyForm />
    </C.Wrap>
  );
};

export default LeadsSurvey;
