import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import FooterNew from "components/FooterNew/FooterNew";
import NewAttitude from "components/NewAttitude/NewAttitude";
import NutritionPlan from "components/NutritionPlan/NutritionPlan";
import RightCoach from "components/RightCoach/RightCoach";
import FirstConsultation from "components/FirstConsultation/FirstConsultation";
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation";
import NextSteps from "components/NextSteps/NextSteps";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
// Styles
import * as C from "./BookAppointment.styles";
import * as G from "styles/General.Styles";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import LeadsSurvey from "components/LeadsSurvey/LeadsSurvey";

const BookAppointment = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    // const URL = "https://felix.foodiary.app/erstgespraech".split('.app')[0] + ".app"
    // const URL = "https://helen-fci81909.foodiary.app".split(".app")[0] + ".app";
    const URL = window.location.href.split(".app")[0] + ".app";

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip>TERMIN BUCHEN</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Erstgespräch <br /> vereinbaren
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  Melde dich bei mir uns wir werden während unserem Kennenlernen
                  deinen persönlichen Ernährungsplan erstellen.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div className="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>
      <G.Container>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <LeadsSurvey />
        </SectionWrapper>

        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <TestimonialSlider />
        </SectionWrapper>
      </G.Container>
      <SectionWrapper margin="7.8rem 0 0">
        <InitialConsultationBox />
      </SectionWrapper>
      <FooterNew />
    </>
  );
};

export default BookAppointment;
