import React from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import { useHistory } from "react-router-dom";
import * as F from "./coachingPlans.styled";
import * as G from "styles/General.Styles";
// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CrossIcon from "assets/images/myCoaching/Icon-Cross.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingPlans = ({ onPaymentModal }) => {
  const history = useHistory();
  const coach = useSelector((state) => state.coach.coach);

  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="nonActive">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal">
            COACHING PLUS
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            49,50 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            Pro Monat
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Bis zu 100% Kostenübernahme durch deine Krankenkasse
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Ernährungsplaner-App mit 1700 Rezepten und smarten Funktionen
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Lernacademy mit Online Ernährungskurs.
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary App Coaching Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        {/* <PopupButton
          className="ct-link-text foodiary-outline-button w-full"
          url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
          rootElement={document.getElementById("root")}
          text="Erstgespräch vereinbaren"
        /> */}
        <button
          className="ct-link-text foodiary-outline-button w-full"
          onClick={() => history.push("/kennenlernen")}
        >
          Erstgespräch vereinbaren
        </button>
      </F.PlanBox>

      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal active">
            COACHING PRO
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            150,00 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            pro Monat
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Ernährungsberater
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary App mit Coaching Funktionen
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Lernacademy mit wöchentlichen Gruppengesprächen
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Online Ernährungskurs für deinen langfristigen Erfolg
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Kostenrückerstattung der Krankenkasse
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <button
          className="ct-link-text foodiary-green-button w-full"
          onClick={() => onPaymentModal()}
        >
          Coaching PRO buchen
        </button>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingPlans;
